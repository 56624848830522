// ** Dropdowns Imports
import { Input } from "reactstrap"
import UserDropdown from "./UserDropdown"
import { useState } from "react"
import { getCookieByName } from "../../../../utility/Utils"
import { runOneSignal } from "../../../../utility/one-signal/OneSignal"

const NavbarUser = () => {
  const [notificationAllowed, setNotificationAllowed] = useState(Notification.permission === "granted")
  return (
    <ul className="nav navbar-nav align-items-center ms-auto">
      <div style={{ fontWeight: 'bold' }}>Allow Notification</div>
      <div className='form-check form-switch ms-1'>
        <Input type='switch' name='status' id='statu'
          onChange={async (e) => {
            const token = getCookieByName('token')
            if (e.target.checked && token) {
              try {
                await runOneSignal()
              } catch {
              } finally {

                if (Notification.permission === "granted") {
                  setNotificationAllowed(true)
                }
              }
            }
          }}
          checked={notificationAllowed}
        />
      </div>
      <UserDropdown />
    </ul>
  )
}
export default NavbarUser
