// ** Third Party Components
import Chart from 'react-apexcharts'

// ** Reactstrap Imports
import { Card, CardHeader, CardTitle, CardBody } from 'reactstrap'

const RadialChart = () => {
    // ** Chart Options
    const options = {
        series: [20],
        chart: {
            height: 250,
            type: 'radialBar',
            margin: 0
        },
        plotOptions: {
            radialBar: {
                startAngle: -135,
                endAngle: 135,
                hollow: {
                    size: '70%'
                },
                dataLabels: {
                    name: {
                        show: false,
                        fontSize: '22px'
                    },
                    value: {
                        fontSize: '40px'
                    }
                    // total: {
                    //   show: true,
                    //   label: 'go',
                    //   formatter:  () => {
                    //     return 249
                    //   }
                    // }
                }
            }
        },
        colors: ['#FFB956'],
        stroke: {
            lineCap: 'round'
        }
    }

    return (
        <Card>
            <CardHeader className='d-flex flex-sm-row flex-column justify-content-md-between align-items-start justify-content-start pb-0'>
                <div>
                    <CardTitle className='mb-75' tag='h4'>
                        Best Table
                    </CardTitle>
                </div>
                <div className='d-flex align-items-center flex-wrap mt-sm-0 mt-1'>
                    B-24
                </div>
            </CardHeader>
            <CardBody className='p-0'>
                <Chart options={options} series={[40]} type='radialBar' margin={0} height={240} />
                <hr />

                <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
                    <div style={{ padding: "0 0.9rem" }}>
                        <p className='m-0 text-center' style={{fontSize:"14px", fontWeight:"400", color:"rgba(185, 185, 195, 1)"}}>Total Customer</p>
                        <p className='m-0 text-center' style={{fontSize:"21px", fontWeight:"600", color:"rgba(94, 88, 115, 1)"}}>786,617</p>
                    </div>
                    <div style={{ padding: "0 0.9rem" }}>
                        <p className='m-0 text-center' style={{fontSize:"14px", fontWeight:"400", color:"rgba(185, 185, 195, 1)"}}>Total Customer</p>
                        <p className='m-0 text-center' style={{fontSize:"21px", fontWeight:"600", color:"rgba(94, 88, 115, 1)"}}>786,617</p>
                    </div>
                </div>
            </CardBody>
        </Card>
    )
}

export default RadialChart
