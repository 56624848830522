import {Disc, User } from "react-feather"
import { QR, Menu, DashBoard, Resturent, Employee, Orders, Reviews, Ads, TableViewIcon } from "../../@core/assets/fonts/feather/icons"

export default [
  {
    id: "dashboard",
    title: "Dashboard",
    icon: <DashBoard size={20} />,
    navLink: "/dashboard"
  },
  {
    id: "restaurant",
    title: "Restaurant",
    icon: <Resturent size={20} />,
    permissions:["allow_profile_edit"],
    children:[
      {
        id: "edit-restaurant",
        title: "Edit Restaurant",
        icon: <Disc size={20} />,
        navLink: "/restaurant/edit"
      }
      // ,
      // {
      //   id: "view_restaurant",
      //   title: "View Restaurant",
      //   icon: <Disc size={20} />,
      //   navLink: "/restaurant/view-profile"
      // }
    ]
  },
  {
    id: "orders",
    title: "Orders",
    icon: <Orders size={20} />,
    permissions:["allow_order"],
    children:[
      {
        id: "live-orders",
        title: "Live orders",
        icon: <Disc size={20} />,
        navLink: "/live-orders"
      },
      {
        id: "orders-history",
        title: "Order History",
        icon: <Disc size={20} />,
        navLink: "/order-history"
      }
    ]
  },
  {
    id: 'table-view',
    title: 'Table View',
    icon: <TableViewIcon size={20}/>,
    navLink: '/table-view',
    permissions:["allow_order"]
  },
  {
    id: 'empolyees',
    title: 'Empolyees',
    icon: <Employee size={20}/>,
    navLink: '/empolyees'
  },
  {
    id: 'mobile_users',
    title: 'Mobile Users',
    icon: <User size={20} />,
    navLink: '/mobile-users'
  },
  {
    id: "menu",
    title: "Menu",
    icon: <Menu size={20} />,
    permissions:["allow_menu_edit"],
    children:[
      {
        id: "restaurant-menu",
        title: "Restaurant Menu",
        icon: <Disc size={20} />,
        navLink: "/restaurant-menu"
      },
      {
        id: "restaurant-combo",
        title: "Restaurant Combos",
        icon: <Disc size={20} />,
        navLink: "/restaurant-combo"
      }
    ]
  },
  {
    id: "reviews",
    title: "Reviews",
    icon: <Reviews/>,
    navLink: "/reviews"
  },
  {
    id: "QR-management",
    title: "QR Management",
    icon: <QR/>,
    navLink: "/qr-management",
    permissions:["allow_qr_edit"]
  }
]