// ** Reducers Imports
import layout from "./layout"
import navbar from "./navbar"
import kitchenQueueRed  from "./kitchenQueue"
import liveOrderByTableId from "./liveOrderByTableId"
import liveTableListRad from "./liveTableListRad"
import liveOrder from "./pagination/liveOrders"
import employeeTable from "./pagination/employeeTable"
import mobileUserTable from "./pagination/mobileUserTable"
import reviewTable from "./pagination/reviewTable"
import qrManagementTable from "./pagination/qrManagementTable"
import restaurantMenuTable from "./pagination/restaurantMenuTable"
import comboTable from "./pagination/comboTable"
import orderHistory from "./pagination/orderHistory"
import authrization from "./authrization"
// import authentication from "./authentication"

const rootReducer = { 
    navbar,
    layout, 
    kitchenQueueRed, 
    liveOrderByTableId, 
    liveTableListRad, 
    liveOrder, 
    employeeTable,
    mobileUserTable,
    reviewTable,
    qrManagementTable,
    restaurantMenuTable,
    comboTable,
    orderHistory,
    authrization
}

export default rootReducer
