// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { handleApiError, instanceV1 } from "../utility/Utils"

export const loadTableListData = createAsyncThunk("loadTableListData", async () => {
    
    const newInstance = instanceV1()
    let response = null
    try {
        response = await newInstance.get('/tables')
    } catch (error) {
        handleApiError(error)
    } finally {
        return response
    }
})

export const liveTableListRed = createSlice({
    name: "liveTableListRed",
    initialState: {
        tableList: [],
        filteredTableList: [],
        filterBase:'all',
        isLoading:false,
        isError:false
    },
    reducers: {
        changeFilterBase: (state, action) => {
            const base = action.payload
            state.filterBase = base
            if (base === 'all') {
                state.filteredTableList = state.tableList
            } else if (base === 'occupied') {
                state.filteredTableList = state.tableList.filter((e) => e.is_occupied === 1)
            } else {
                state.filteredTableList = state.tableList.filter((e) => e.is_occupied === 0)
            }
          }
    },
    extraReducers: (builder) => {
        builder.addCase(loadTableListData.fulfilled, (state, action) => {
            if (action.payload) {
                state.tableList = action.payload.data.data
                state.isError = false
//updating table list 
                if (state.filterBase === 'all') {
                    state.filteredTableList = action.payload.data.data
                } else if (state.filterBase === 'occupied') {
                    state.filteredTableList = action.payload.data.data.filter((e) => e.is_occupied === 1)
                } else {
                    state.filteredTableList = action.payload.data.data.filter((e) => e.is_occupied === 0)
                }
            } else {
                state.isError = true
            }
            state.isLoading = false
        })
        builder.addCase(loadTableListData.rejected, (state) => {
             state.isLoading = false
        })
        builder.addCase(loadTableListData.pending, (state) => {
            state.isLoading = true
            
        })
    }
})

export const { changeFilterBase } = liveTableListRed.actions
export default liveTableListRed.reducer
