// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { handleApiError, instanceV1 } from "../utility/Utils"

export const loadOrderByTableId = createAsyncThunk("liveOrderByTableId", async (tableId = 0) => {
   const newInstance = instanceV1()
   let response = null
   if (tableId !== 0) {
      try {
         response = await newInstance.get(`/live-order-table/${tableId}`, {
            cache: "no-chache"
         })
      } catch (error) {
         handleApiError(error)
      } finally {
         return response
      }
   }
})

export const liveOrderByTableId = createSlice({
   name: "liveOrderByTableId",
   initialState: {
      orderDetail: {},
      billingAmmount: 0,
      isLoading: false,
      isError: false
   },
   reducers: {
      reset: (state) => {
         state.orderDetail = []
         state.billingAmmount = 0
         state.isLoading = false
         state.isError = false
      }
   },
   extraReducers: (builder) => {
      builder.addCase(loadOrderByTableId.fulfilled, (state, action) => {
         // console.log(state)
         if (action.payload) {
            if (action.payload.data.data[0]) {
               // state.orderDetail = action.payload.data.data[0]
               state.billingAmmount = action.payload.data.data[0].orderDetails
                  .reduce((p, n) => {
                     if (n.status.toString() === "0" || n.status.toString() === "1") {
                        // status means cooking status
                        return p
                     } else {
                        // return p + ((n.quantity || 1) * (n.order_item_price + n.orderAddOns.reduce((ap, an) => {
                        //     return ap + an.order_addon_price + 0
                        // }, 0)))
                        //  console.log(n)
                        return p + (n.quantity || 1) * (n.order_item_price + n.order_item_tax)
                     }
                  }, 0)
                  .toFixed(2)

               // Create a map with unique keys based on the specified properties and instruction
               const uniqueKeyMap = new Map()
               // console.log(uniqueKeyMap)
               // console.log(action.payload.data.data[0].orderDetails)
               for (const item of action.payload.data.data[0].orderDetails) {
                  const key = `${item.cafe_menu_item_id}-${item.cafe_menu_variant_id}-${item.status}-${item.cafe_menu_combo_id}-${
                     item.cooking_instruction
                  }-${item.custom_item_name}-${JSON.stringify(item.orderAddOns.length)}`

                  // console.log(item)
                  if (uniqueKeyMap.has(key)) {
                     const exixtingItem = uniqueKeyMap.get(key)
                     // console.log(exixtingItem)
                     exixtingItem.count++
                     exixtingItem.order_detail_ids.push(item.cafe_order_item_id)
                     //   uniqueKeyMap.set(key, {...item, quantity:item.quantity || 1})
                  } else {
                     item.count = 1
                     item.order_detail_ids = [item.cafe_order_item_id]
                     uniqueKeyMap.set(key, item)
                  }
               }

               // Get the reduced array of unique elements
               state.orderDetail = { ...action.payload.data.data[0], orderDetails: Array.from(uniqueKeyMap.values()) }

               state.isError = false
            }
         } else {
            state.isError = true
         }
         state.isLoading = false
      })
      builder.addCase(loadOrderByTableId.rejected, (state) => {
         state.isLoading = false
      })
      builder.addCase(loadOrderByTableId.pending, (state) => {
         state.isLoading = true
      })
   }
})

export const { reset } = liveOrderByTableId.actions

export default liveOrderByTableId.reducer
