import axios from "axios"
import toast from "react-hot-toast"
import { Navigate, useLocation } from "react-router-dom"
import { DefaultRoute } from "../router/routes"
import * as XLSX from "xlsx"

// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = (obj) => Object.keys(obj).length === 0

// ** Returns K format from a number
export const kFormatter = (num) => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num)

// ** Converts HTML to string
export const htmlToString = (html) => html.replace(/<\/?[^>]+(>|$)/g, "")

// Get Cookie by name
export function getCookieByName(cookieName) {
   // Get name followed by anything except a semicolon
   const cookiestring = RegExp(`${cookieName}=[^;]+`).exec(document.cookie)
   // Return everything after the equal sign, or an empty string if the cookie name not found
   return decodeURIComponent(!!cookiestring ? cookiestring.toString().replace(/^[^=]+./, "") : "")
}
// remove Cookie by name
export function removeCookieByName(cookieName) {
   // set expire time now
   document.cookie = `${cookieName}=;expires=${new Date(0).toUTCString()}`
}

// log out
export const logout = async () => {
   try {
      const token = getCookieByName("token")
      if (token) {
         const newInstance = axios.create({
            baseURL: process.env.REACT_APP_BASE_API_URL,
            headers: {
               Authorization: `bearer ${token}`,
               "Cache-Control": "no-cache"
            }
         })
         newInstance.put("/logout")
      }
   } catch (err) {
      if (false) console.warn(err)
      toast.error("Something went wrong. Try again later")
   } finally {
      document.cookie = `token=;expires=${new Date(0).toUTCString()}`
      localStorage.removeItem("user")
      localStorage.removeItem("is_onboard_complete")
      window.location.href = "/login"
   }
   // window.location.reload(false)
}

// ** Axios instance
export const instance = () => {
   const token = getCookieByName("token")
   if (!token) {
      logout()
   }
   return axios.create({
      baseURL: process.env.REACT_APP_BASE_API_URL,
      headers: {
         Authorization: `bearer ${token}`,
         "Cache-Control": "no-cache"
      }
   })
}
export const instancePDF = () => {
   const token = getCookieByName("token")
   if (!token) {
      logout()
   }
   return axios.create({
      baseURL: process.env.REACT_APP_PDF_API_URL,
      headers: {
         Authorization: `bearer ${token}`,
         "Cache-Control": "no-cache"
      }
   })
}
export const instanceV1 = () => {
   const token = getCookieByName("token")
   if (!token) {
      logout()
   }
   return axios.create({
      baseURL: `${process.env.REACT_APP_BASE_API_URL}/api/v1`,
      headers: { Authorization: `bearer ${token}` }
   })
}
// ** Checks if the passed date is today
const isToday = (date) => {
   const today = new Date()
   return (
      /* eslint-disable operator-linebreak */
      date.getDate() === today.getDate() && date.getMonth() === today.getMonth() && date.getFullYear() === today.getFullYear()
      /* eslint-enable */
   )
}

export const getSqlDateFormate = () => {
   //  26-01-2023 10:10:10
   const times = new Date()
   return `${times.getFullYear()}-${times.getMonth() + 1}-${times.getDate()} ${times.getHours()}:${times.getMinutes()}:${times.getSeconds()}`
}

/**
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (value, formatting = { month: "short", day: "numeric", year: "numeric" }) => {
   // Mar 3, 2023
   if (!value) return value
   return new Intl.DateTimeFormat("en-US", formatting).format(new Date(value))
}

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
   const date = new Date(value)
   let formatting = { month: "short", day: "numeric" }

   if (toTimeForCurrentDay && isToday(date)) {
      formatting = { hour: "numeric", minute: "numeric" }
   }

   return new Intl.DateTimeFormat("en-US", formatting).format(new Date(value))
}

export const formatDateTime = (value) => {
   // Mar 3, 9:27 AM
   const formatting = { month: "short", day: "numeric", hour: "numeric", minute: "numeric" }
   return new Intl.DateTimeFormat("en-US", formatting).format(new Date(value))
}

export const formatDateTimeFigma = (value) => {
   //01/01/2023, 11:10 pm
   const formatting = { day: "numeric", month: "numeric", year: "numeric", hour: "numeric", minute: "numeric", hour12: true }
   try {
      const j = new Intl.DateTimeFormat("en-US", formatting).format(new Date(value))
      return j
   } catch {
      return value
   }
}
export const formatTime = (value) => {
   const formatting = { hour12: false, hour: "numeric", minute: "numeric", second: "numeric" }
   return new Intl.DateTimeFormat("en-US", formatting).format(new Date(value))
}
export const format12Time = (value) => {
   // 12:13:15 to 12:13am
   if (Number(value.slice(0, 2)) < 13) {
      return `${value.slice(0, 5)}am`
   } else {
      return `${Number(value.slice(0, 2)) - 12}:${value.slice(3, 5)}pm`
   }
}
/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => localStorage.getItem("userData")
export const getUserData = () => JSON.parse(localStorage.getItem("user"))

export const handleApiError = (error) => {
   toast.dismiss()
   if (error.response) {
      if (error.response.status === 401) {
         logout()
      } else {
         if (error.response.data === undefined) {
            // toast.error(error.message)
            toast.error("Something went wrong. Try again later")
         } else {
            // console.log('Invalid token or expired!', error.response.data.msg.startsWith('Invalid token or expired!'))

            if (error.response.data.msg) {
               toast.error(error.response.data.msg)
            } else {
               toast.error("Something went wrong. Try again later")
               // error.message === 'Request failed with status code 500' ? toast.error(error.response.data) : toast.error('ERROR')
            }
         }
      }
   } else {
      console.warn(error)
   }
}
/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = (user) => {
   if (user) return DefaultRoute
   return "/login"
}

export const getRestroSetUpRoute = () => {
   return "/restaurant-setup"
}
// Upload Image to Cloude Flare
export const uploadImage = async (file) => {
   const newInstance = instance()
   let img_id = ""
   try {
      const j = await newInstance.post(
         "/api/user/admin/uploadImage",
         { file },
         {
            headers: {
               "Content-Type": "multipart/form-data"
            }
         }
      )
      console.log(j)
      console.log(j.data.id)
      img_id = `${j.data.id}`
   } catch (err) {
      console.warn(err)
   }
   return img_id
}

// get query Strings

export const useQuery = () => {
   return new URLSearchParams(useLocation().search)
}

// Get image from cloud flare using image id
export const getImageCloudFlare = (image_id, quality = process.env.REACT_APP_CLOUD_IMAGE_Default_Extention) => {
   return `${process.env.REACT_APP_CLOUD_IMAGE_BASE_URL}/${image_id}/${quality}`
}

// get amozonaws pdf name
export const getAmozonAWSName = (pdf) => {
   try {
      const j = decodeURIComponent(pdf.split("documents/")[1])
      return j
   } catch {
      return pdf
   }
}
// ** React Select Theme Colors
export const selectThemeColors = (theme) => ({
   ...theme,
   colors: {
      ...theme.colors,
      primary25: "#7367f01a", // for option hover bg-color
      primary: "#7367f0", // for selected option bg-color
      neutral10: "rgba(0, 0, 0, 0.12)", // for tags bg-color
      neutral20: "#ededed", // for input border-color
      neutral30: "#ededed" // for input hover border-color
   }
})

export const exportObjectToExcel = (data, fileName = "newfile") => {
   const wb = XLSX.utils.book_new()
   const ws = XLSX.utils.json_to_sheet([])
   XLSX.utils.sheet_add_aoa(ws, [Object.keys(data[0]).map((e) => e.split("_").join(" "))])
   // XLSX.utils.sheet_add_aoa(ws, [Object.keys(data[0])])

   //Starting in the second row to avoid overriding and skipping headers
   XLSX.utils.sheet_add_json(ws, data, { origin: "A2", skipHeader: true })

   XLSX.utils.book_append_sheet(wb, ws, "Sheet1")
   const date = new Date()
   XLSX.writeFile(
      wb,
      `${fileName}_${new Intl.DateTimeFormat("en-US", { day: "numeric" }).format(new Date(date))}${new Intl.DateTimeFormat("en-US", {
         month: "short"
      }).format(new Date(date))}${date.getFullYear()}_${date.getTime()}.xlsx`
   )
}

export const customStyles = {
   rows: {
      style: {
         whiteSpace: "pre-line"
      }
   },
   subHeader: {
      style: {
         padding: "0"
      }
   },
   headCells: {
      style: {
         justifyContent: "center",
         fontFamily: "Inter",
         fontWeight: "600",
         fontSize: "0.75rem",
         lineHeight: "0.9375rem",
         letterSpacing: "1px",
         color: "#768DA9",
         whiteSpace: "pre-line",
         textTransform: "uppercase",
         textAlign: "center"
         // whiteSpace:'wrap'
      }
   },
   cells: {
      style: {
         justifyContent: "center",
         fontFamily: "Inter",
         fontWeight: "500",
         fontSize: "0.875rem",
         // paddingLeft : '10px',
         lineHeight: "1.3125rem",
         color: "#1A264E",
         textAlign: "center",
         padding: "0"
      }
   }
}

export const excelToObject = async (file) => {
   try {
      const reader = new FileReader()
      reader.readAsBinaryString(file)
      reader.onload = function (e) {
         const data = e.target.result
         const workbook = XLSX.read(data, {
            type: "binary",
            skipHeader: false
         })
         const result = {}
         workbook.SheetNames.forEach(function (sheetName) {
            const roa = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName])
            if (roa.length > 0) {
               if (Object.keys(roa[0]).includes("__EMPTY")) {
                  toast.error("Please Provide header to the file")
               } else {
                  result[sheetName] = roa
               }
            } else {
               toast.error("No Data found in file")
            }
         })
         console.log(result)
      }
   } catch (error) {
      console.log(error)
   }
}
