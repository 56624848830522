import { useState, useEffect } from "react"
import { AuthContext } from "./Can"
import { instance, handleApiError, getCookieByName } from "@utils"

/**
 * This component is contextprovider consist of
 * signup login states and displaydata.
 *  this is responsible for main output display among
 * (LogIn,SignUp,Congratulation,Main dashboard)
 * and aslo provide display data after log in.
 * @param   {props} param1 children (all components).
 * @return  {Component}  providercomponent to all
 * components of apps.
 */
const AuthProvider = (props) => {
   /**
    * This get login status.
    */

   /**
    * This get display data, provided at Auth.
    */

   const [userData, setuserData] = useState({})

   const userDataSetter = (data) => {
      setuserData({ ...data })
   }
   const getUserProfile = async () => {
      const userData = JSON.parse(localStorage.getItem('user')) || {}
      userDataSetter({ ...userData, networkStatus: (navigator.onLine ? "online" : "offline") })
      try {
         const newInstance = instance()
         const j = await newInstance.get('/get-profile')
         const profile = j.data?.getProfile?.length ? j.data.getProfile[0] : {
            address_proof_id_url: '',
            email: "",
            first_name: '',
            last_name: '',
            mobile_number: null,
            photo_proof_id_url: '',
            profile_pic_image_id: '',
            user_admin_id: null
         }
         const permissions = j.data?.cafe_permission?.length ? j.data.cafe_permission[0] : {}
         const data = { ...profile, cafe_permission: { ...permissions }, api_version: j.data.api_version }

         userDataSetter({ ...data, networkStatus: (navigator.onLine ? "online" : "offline") })
         localStorage.setItem("user", JSON.stringify({ ...data }))
         localStorage.setItem("api-version", JSON.stringify(j?.data?.api_version))
      } catch (error) {
         handleApiError(error)
         const userData = JSON.parse(localStorage.getItem('user')) || {}
         userDataSetter({ ...userData, networkStatus: (navigator.onLine ? "online" : "offline") })
      }
   }
   useEffect(() => {
      if (getCookieByName("token")) {
         getUserProfile()
      }
      return () => { }
   }, [])

   return (
      <AuthContext.Provider value={[userData, userDataSetter]}>
         {props.children}
      </AuthContext.Provider>
   )
}
export default AuthProvider
