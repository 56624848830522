// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { handleApiError, instance } from "../utility/Utils"

export const GetUserDetail = createAsyncThunk("GetUserDetail", async () => {
    console.log("hello")
    const newInstance = instance()
    let response = null
    try {
        response = await newInstance.get("/get-profile")
    } catch (error) {
        handleApiError(error)
    } finally {
        return response
    }
})

export const authSlice = createSlice({
    name: 'authentication',
    initialState: {
        userData: { ...JSON.parse(window.localStorage.getItem('user')) ?? {} },
        isLoading: false
    },
    extraReducers: (builder) => {
        builder.addCase(GetUserDetail.fulfilled, (state, action) => {
            state.isLoading = false
            console.log(action.payload.data)
            const profile = action.payload.data?.getProfile?.length ? action.payload.data.getProfile[0] : {
                address_proof_id_url: '',
                email: "",
                first_name: '',
                last_name: '',
                mobile_number: null,
                photo_proof_id_url: '',
                profile_pic_image_id: '',
                user_admin_id: null
            }
            const permissions = action.payload.data?.cafe_permission?.length ? action.payload.data.cafe_permission[0] : {}
            const data = { ...profile, cafe_permission: { ...permissions }, api_version: action.payload.data.api_version }
            state.userData = { ...data, networkStatus: (navigator.onLine ? "online" : "offline") }
        })
        builder.addCase(GetUserDetail.rejected, (state) => {
            state.isLoading = false
        })
        builder.addCase(GetUserDetail.pending, (state) => {
            state.isLoading = true

        })
    }
})

// export const { } = authSlice.actions

export default authSlice.reducer
