// ** React Imports
import { Navigate } from "react-router-dom"
import { useContext, Suspense } from "react"
import { getCookieByName, getRestroSetUpRoute, logout } from "../../../utility/Utils"
import { AuthContext } from "../../../utility/context/Can"
// ** Context Imports
// import { AbilityContext } from "@src/utility/context/Can"

const PrivateRoute = ({ children, route }) => {
  // ** Hooks & Vars
  // const ability = useContext(AbilityContext)
  const userCred = getCookieByName('token')
  const user = JSON.parse(localStorage.getItem("user")) 
  const onboard = JSON.parse(localStorage.getItem("is_onboard_complete"))

  if (route) {
    // let action = null
    // let resource = null
  const [userData] = useContext(AuthContext)
    let restrictedRoute = false
    const onboardRoute = route?.meta?.onboard || false

    if (route.meta) {
      // action = route.meta.action
      // resource = route.meta.resource
      restrictedRoute = route.meta.restricted
    }
    if (!userCred || !user) {
      logout()
      return <Navigate to="/login" />
    }
    if (user && restrictedRoute) {
      return <Navigate to="/" />
    }
    if (user && !onboardRoute && !onboard) {
      return <Navigate to={getRestroSetUpRoute()} />
    }

    if (user && onboardRoute && onboard) {
      return <Navigate to="/" />
    }
    if (route.permissions?.length) {
      for (const i of route.permissions) {
        if (!(userData?.cafe_permission || {})[i]) {
          return <Navigate to="/settings" />
        }
      }
    }
  }

  return <Suspense fallback={null}>{children}</Suspense>
}

export default PrivateRoute
