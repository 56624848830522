// ** React Imports
import { Suspense } from "react"
import { Navigate } from "react-router-dom"

// ** Utils
import { getUserData, getHomeRouteForLoggedInUser } from "@utils"

const PublicRoute = ({ children, route }) => {

  if (route) {
    const user = getUserData()

    const restrictedRoute = route.meta && route.meta.restricted

    if (user && restrictedRoute) {
      return <Navigate to={getHomeRouteForLoggedInUser(user)} />
    }
  }
  // if (route) {
    
  //   const user = getUserData()
  //   const onboard = JSON.parse(localStorage.getItem("is_onboard_complete"))
  //   console.log(user, onboard)
  //   const restrictedRoute = route.meta && route.meta.restricted
  //   console.log("public", user, onboard)

  //   // if (!user) {
  //   //   return <Navigate to={"/login"} />
  //   // }
  //   if (user && !onboard) {
  //     return <Navigate to={getRestroSetUpRoute()} />
  //   } else if (user && restrictedRoute) {
  //     return <Navigate to={getHomeRouteForLoggedInUser(user)} />
  //   } 
  // }

  return <Suspense fallback={null}>{children}</Suspense>
}

export default PublicRoute
 