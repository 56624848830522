// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
// import {  instance } from "@src/utility/Utils"
import { handleApiError, instanceV1 } from "../utility/Utils"

export const LoadKitchenQueueData = createAsyncThunk("LoadKitchenQueueData", async () => {
    
    const newInstance = instanceV1()
    let response = null
    try {
        response = await newInstance.get("/kitchen-queue")
    } catch (error) {
        handleApiError(error)
    } finally {
        return response
    }
})

export const kitchenQueueRed = createSlice({
    name: "kitchenQueueRed",
    initialState: {
        kitchenQueueData: [],
        isLoading:false,
        isError:false
    },
    extraReducers: (builder) => {
        builder.addCase(LoadKitchenQueueData.fulfilled, (state, action) => {
            if (action.payload) {
                state.kitchenQueueData = action.payload.data.DATA
                if (action.payload.data.DATA) {
                    const result = Object.values((action.payload.data.DATA || []).reduce((acc, item) => {
                      const key = `${item.cafe_order_list_id}-${item.status}-${item.cafe_menu_item_id}-${item.cafe_menu_combo_id}`
                      if (acc[key]) {
                        acc[key].count += 1
                        acc[key].order_detail_ids.push(item.cafe_order_item_id) 
                      } else {
                        acc[key] = {
                          ...item,
                          count: 1,
                          order_detail_ids: [item.cafe_order_item_id]
                        }
                      }
                      return acc
                    }, {}))
                    state.kitchenQueueData = result
                }
                state.isError = false
            } else {
                state.isError = true
            }
            state.isLoading = false
        })
        builder.addCase(LoadKitchenQueueData.rejected, (state) => {
             state.isLoading = false
        })
        builder.addCase(LoadKitchenQueueData.pending, (state) => {
            state.isLoading = true
            
        })
    }
})


export default kitchenQueueRed.reducer
