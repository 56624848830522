// ** React Imports
import { Fragment, lazy }  from "react"
import { Navigate } from "react-router-dom"
// ** Layouts
import BlankLayout from "@layouts/BlankLayout"
import VerticalLayout from "@src/layouts/VerticalLayout"
import HorizontalLayout from "@src/layouts/HorizontalLayout"
import LayoutWrapper from "@src/@core/layouts/components/layout-wrapper"


// ** Route Components
import PublicRoute from '@components/routes/PublicRoute'
import PrivateRoute from '@components/routes/PrivateRoute'
// ** Utils
import { isObjEmpty } from "@utils"
import { BasicFunction } from "../../views/Basic"

const RegisterMultiSteps = lazy(() => import("../../views/Auth/register-multi-steps"))
const EditEmployee  = lazy(() => import("../../views/Employees/EditEmpolyee"))
const MenuAddMenu  = lazy(() => import("../../views/menuItem/addMenu/MenuAddMenu"))
const EditAddMenu  = lazy(() => import("../../views/menuItem/addMenu/EditAddMenu"))
const AddMenu  = lazy(() => import("../../views/menuItem/addMenu/AddMenu"))
const GernalInfo  = lazy(() => import("../../views/Resturents/editResturent/GernalInfo"))
const TimeAndPrice  = lazy(() => import("../../views/Resturents/editResturent/TimeAndPrice"))
const AddressInfo  = lazy(() => import("../../views/Resturents/editResturent/AddressInfo"))
const EditSocialInfo  = lazy(() => import("../../views/Resturents/editResturent/EditSocialInfo"))
const FeaturedImage  = lazy(() => import("../../views/Resturents/editResturent/FeaturedImage"))
const ImageGallery  = lazy(() => import("../../views/Resturents/editResturent/ImageGallery"))
const EditDocuments  = lazy(() => import("../../views/Resturents/editResturent/editDocuments"))
const QRManagement  = lazy(() => import("../../views/QRManagement/QRManagement"))
const LiveOrders  = lazy(() => import("../../views/orders/liveOrders/LiveOrders"))
const EditOrders  = lazy(() => import("../../views/orders/liveOrders/EditOrders"))
const OrderHistory  = lazy(() => import("../../views/orders/orderHistory/OrderHistory"))
const OrderHistoryDetails  = lazy(() => import("../../views/orders/orderHistory/OrderHistoryDetails"))
const MobileUsers  = lazy(() => import("../../views/Users/MobileUsers"))
const AddUserProfile  = lazy(() => import("../../views/Users/AddUserProfile"))
const Reviews  = lazy(() => import("../../views/reviews/Reviews"))
const EditReviews  = lazy(() => import("../../views/reviews/EditReviews"))
const OrderInvoice  = lazy(() => import("../../views/orders/orderHistory/OrderInvoice"))
const TableView  = lazy(() => import("../../views/tableView"))
const OnboardPass  = lazy(() => import("../../views/Auth/OnboardPass"))
const AddTable  = lazy(() => import("../../views/QRManagement/AddTable"))
const EditTable  = lazy(() => import("../../views/QRManagement/EditTable"))
const Combo  = lazy(() => import("../../views/menuItem/Combo/ComboTable"))

const getLayout = {
  blank: <BlankLayout />,
  vertical: <VerticalLayout />,
  horizontal: <HorizontalLayout />
}

// ** Document title
const TemplateTitle = "Eats"

const Login = lazy(() => import("../../views/Auth/Login"))
const ForgotPassword = lazy(() => import("../../views/Auth/ForgotPassword"))
const EmailSent = lazy(() => import("../../views/Auth/EmailSent"))
const PasswordResetSuccess = lazy(() => import("../../views/Auth/PasswordResetSuccess"))
const ResetPassword = lazy(() => import("../../views/Auth/ResetPassword"))
const NotAuthorized = lazy(() => import("../../views/NotAuthorized"))
const Dashboard = lazy(() => import("../../views/Dashboard"))
const Setting = lazy(() => import("../../views/Dashboard/settings/Setting"))
const Error = lazy(() => import("../../views/Error"))
const EditResturent = lazy(() => import("../../views/Resturents/editResturent/EditResturent"))
const ViewProfile = lazy(() => import("../../views/Resturents/ViewProfile"))
const EmployeesTableView = lazy(() => import("../../views/Employees/TableView"))
const AddEmployee = lazy(() => import("../../views/Employees/AddEmployee"))
const OrderMenu = lazy(() => import("../../views/tableView/order/index"))


// ** Default Route
const DefaultRoute = "/table-view"
// const DefaultRoute = "/dashboard"

// ** Set Home Route
const getHomeRoute = () => {
  const user = JSON.parse(localStorage.getItem("user"))
  if (user) {
    return DefaultRoute
  } else {
    return '/login'
  }
}

// ** Merge Routes
const Routes = [
  {
    path: "/",
    index: true,
    element: <Navigate replace to={getHomeRoute()} />,
    meta: {
      layout: "blank"
    }
  },
  {
    path: "/login",
    element: <Login />,
    meta: {
      layout: 'blank',
      publicRoute: true,
      restricted: true
    }
  },
  {
    path: "/forgot-password",
    element: <ForgotPassword />,
    meta: {
      layout: 'blank',
      publicRoute: true,
      restricted: true
    }
  },
  {
    path: "/onboard",
    element: <OnboardPass />,
    meta: {
      layout: 'blank',
      publicRoute: true,
      restricted: true
    }
  },
  {
    path: "/restaurant-setup",
    element: <RegisterMultiSteps />,
    meta: {
      layout: 'blank',
      onboard: true
    }
  },
  {
    path: "/reset-password",
    element: <ResetPassword />,
    meta: {
      layout: 'blank',
      publicRoute: true
    }
  },
  {
    path: "/email-sent",
    element: <EmailSent />,
    meta: {
      layout: 'blank',
      publicRoute: true
    }
  },
  {
    path: "/password-reset-success",
    element: <PasswordResetSuccess />,
    meta: {
      layout: 'blank',
      publicRoute: true
    }
  },
  {
    path: "/not-authrize",
    element: <NotAuthorized />,
    meta: {
      layout: 'blank',
      publicRoute: true
    }
  },
  {
    path: "/dashboard",
    element: <Dashboard />
    // ,
    // permissions:["not_allowed"]
  },
  {
    path: "/settings",
    element: <Setting />
  },
  {
    path: "/restaurant/edit",
    element: <EditResturent />,
    permissions:["allow_profile_edit"],
    children: [
      {
        path: '/restaurant/edit',
        element: <Navigate to='/restaurant/edit/gernal-info' />,
        permissions:["allow_profile_edit"]
      },
      {
        path: '/restaurant/edit/gernal-info',
        element: <GernalInfo />,
        permissions:["allow_profile_edit"]
      },
      {
        path: '/restaurant/edit/time&price',
        element: <TimeAndPrice />,
        permissions:["allow_profile_edit"]
      },
      {
        path: '/restaurant/edit/address-info',
        element: <AddressInfo />,
        permissions:["allow_profile_edit"]
      },
      {
        path: '/restaurant/edit/social-info',
        element: <EditSocialInfo />,
        permissions:["allow_profile_edit"]
      },
      {
        path: '/restaurant/edit/featured-image',
        element: <FeaturedImage />,
        permissions:["allow_profile_edit"]
      },
      {
        path: '/restaurant/edit/image-gallery',
        element: <ImageGallery />,
        permissions:["allow_profile_edit"]
      },
      {
        path: '/restaurant/edit/document',
        element: <EditDocuments />,
        permissions:["allow_profile_edit"]
      }
    ]
  },
  {
    path: "/live-orders",
    element: <LiveOrders />,
    permissions:["allow_order"]
  },
  {
    path: "/live-orders/edit/:id",
    element: <EditOrders />,
    permissions:["allow_order"]
  },
  {
    path: "/order-history",
    element: <OrderHistory />,
    permissions:["allow_order"]
  },
  {
    path: "/order-history/invoice/:id",
    element: <OrderInvoice />,
    permissions:["allow_order"]
  },
  {
    path: "/order-history/details/:id",
    element: <OrderHistoryDetails />,
    permissions:["allow_order"]
  },
  {
    path: "/restaurant/view-profile",
    element: <ViewProfile />,
    permissions:["allow_profile_edit"]
  },
  {
    path: "/table-view",
    element: <TableView />,
    permissions:["allow_order"]
  },
  {
    path: "/table-view/take-order/:id",
    element: <OrderMenu />,
    permissions:["allow_order"]
  },
  {
    path: "/table-view/take-away-order/:id",
    element: <OrderMenu />,
    permissions:["allow_order"]
  },
  {
    path: "/empolyees",
    element: <EmployeesTableView />
  },
  {
    path: "/empolyees/add",
    element: <AddEmployee />
  },
  {
    path: "/empolyees/edit/:id",
    element: <EditEmployee />
  },
  {
    path: "/mobile-users",
    element: <MobileUsers />
  },
  {
    path: "/mobile-users/add",
    element: <AddUserProfile />
  },
  {
    path: "/restaurant-menu",
    element: <MenuAddMenu />,
    permissions:["allow_menu_edit"]
  },
  {
    path: "/restaurant-menu/add",
    element: <AddMenu />,
    permissions:["allow_menu_edit"]
  },
  {
    path: "/restaurant-menu/edit/:id",
    element: <EditAddMenu />,
    permissions:["allow_menu_edit"]
  },
  {
    path: "/restaurant-combo",
    element: <Combo />,
    permissions:["allow_menu_edit"]
  },
  {
    path: "/reviews",
    element: <Reviews />
  },
  {
    path: "/reviews/edit/:id",
    element: <EditReviews />
  },
  {
    path: "/qr-management",
    element: <QRManagement />,
    permissions:["allow_qr_edit"]
  },
  {
    path: "/qr-management/add",
    element: <AddTable />,
    permissions:["allow_qr_edit"]
  },
  {
    path: "/qr-management/edit/:id",
    element: <EditTable />,
    permissions:["allow_qr_edit"]
  },
  {
    path: "/test",
    element: <BasicFunction />,
    permissions:["not_allowed"]
  },
  {
    path: "/*",
    element: <Error />,
    meta: {
      layout: "blank"
    }
  }
]

const getRouteMeta = (route) => {
  if (isObjEmpty(route.element.props)) {
    if (route.meta) {
      return { routeMeta: route.meta }
    } else {
      return {}
    }
  }
}

// ** Return Filtered Array of Routes & Paths
const MergeLayoutRoutes = (layout, defaultLayout) => {
  const LayoutRoutes = []

  if (Routes) {
    Routes.filter((route) => {
      let isBlank = false
      // ** Checks if Route layout or Default layout matches current layout
      if (
        (route.meta && route.meta.layout && route.meta.layout === layout) ||
        ((route.meta === undefined || route.meta.layout === undefined) &&
          defaultLayout === layout)
      ) {
        let RouteTag = PrivateRoute
        // const RouteTag = PublicRoute

        // ** Check for public or private route
        if (route.meta) {
          route.meta.layout === "blank" ? (isBlank = true) : (isBlank = false)
          RouteTag = route.meta.publicRoute ? PublicRoute : PrivateRoute
        }
        if (route.element) {
          const Wrapper =
            // eslint-disable-next-line multiline-ternary
            isObjEmpty(route.element.props) && isBlank === false
              ? // eslint-disable-next-line multiline-ternary
              LayoutWrapper
              : Fragment

          route.element = (
            <Wrapper {...(isBlank === false ? getRouteMeta(route) : {})}>
              <RouteTag route={route}>{route.element}</RouteTag>
            </Wrapper>
          )
        }

        // Push route to LayoutRoutes
        LayoutRoutes.push(route)
      }
      return LayoutRoutes
    })
  }
  return LayoutRoutes
}

const getRoutes = (layout) => {
  const defaultLayout = layout || "vertical"
  const layouts = ["vertical", "horizontal", "blank"]

  const AllRoutes = []

  layouts.forEach((layoutItem) => {
    const LayoutRoutes = MergeLayoutRoutes(layoutItem, defaultLayout)

    AllRoutes.push({
      path: "/",
      element: getLayout[layoutItem] || getLayout[defaultLayout],
      children: LayoutRoutes
    })
  })
  return AllRoutes
}

export { DefaultRoute, TemplateTitle, Routes, getRoutes }
