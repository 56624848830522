// import React, { FC, useState } from "react";
// import { useState } from "react"
// import { ReactSortable } from "react-sortablejs"

import { NavLink } from "react-router-dom"
import { Button, ButtonGroup, Col, Row } from "reactstrap"
import { useContext } from "react"
import { ThemeColors } from '@src/utility/context/ThemeColors'
import LineGraph from "./report/GraphComp"
import RadialChart from "./report/RadialChart"
import BarGraph from "./report/BarGraph"
import 'chart.js/auto'
import '@styles/react/libs/flatpickr/flatpickr.scss'

// export const BasicFunction = () => {
//   const [stat, setState] = useState([
//     { id: 1, name: "shrek", d:'sjhggggggggggggggggggggf', selected:true},
//     { id: 2, name: "fiona", d:"iiiiiiiiiiiiiiiiiiiiiiiiii" }
//   ])
//   const [st, setSt] = useState([
//     { id: 3, name: "mmmmmmmmmmm", d:'111111111111111', selected:true},
//     { id: 4, name: "kkkkkkkkkk", d:"333333333333333" }
//   ])

//   return (
//     <>
//     <ReactSortable 
//     group="groupName"
//     animation={200}
//     list={stat} setList={setState}>

//       {stat.map((item) => (
//         <div key={item.id}>
//             <span onClick={() => console.log(item.id)}>{item.name}</span>
//             <span>{item.d}</span>

//         </div>

//       ))}
//     </ReactSortable>
//     <ReactSortable 
//     group="groupName"
//     animation={200}
//     list={st} setList={setSt}>
//       {st.map((item) => (
//         <div key={item.id}>
//             <span onClick={() => console.log(item.id)}>{item.name}</span>
//             <span>{item.d}</span>
//         </div>
//       ))}
//     </ReactSortable>
//     </>
//   )
// }

export const BasicFunction = () => {
  const { colors } = useContext(ThemeColors)
  console.log(colors)
  return <>
      <ButtonGroup className='mb-1' style={{overflowX:"auto", maxWidth:"100%"}}>
        <Button tag={NavLink} className='no-wrap' to={`/restaurant/edit/gernal-info`} outline  >
          By Table
        </Button>
        <Button tag={NavLink} className='no-wrap' to={`/restaurant/edit/time&price`} outline   >
          By Food Item
        </Button>
        <Button tag={NavLink} className='no-wrap' to={`/restaurant/edit/address-info`} outline  >
          By Day
        </Button>
        <Button tag={NavLink} className='no-wrap' to={`/restaurant/edit/address-info`} outline  >
          By Time
        </Button>
      </ButtonGroup>
    <Row >
      <Col sm={12} md={8}>
        <LineGraph direction={'ltr'}
          warning={colors.primary.light} />
      </Col>
      <Col sm={12} md={4}>
        <RadialChart />
      </Col>
    </Row>
    <BarGraph success={'#01A879'} labelColor={'#6E6B7B'} gridLineColor={'#EBE9F1'} />
  </>
} 